exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loja-js": () => import("./../../../src/pages/loja.js" /* webpackChunkName: "component---src-pages-loja-js" */),
  "component---src-pages-magic-collection-js": () => import("./../../../src/pages/magic-collection.js" /* webpackChunkName: "component---src-pages-magic-collection-js" */),
  "component---src-pages-magnetic-collection-js": () => import("./../../../src/pages/magnetic-collection.js" /* webpackChunkName: "component---src-pages-magnetic-collection-js" */),
  "component---src-pages-obrigado-js": () => import("./../../../src/pages/obrigado.js" /* webpackChunkName: "component---src-pages-obrigado-js" */),
  "component---src-pages-parcerias-js": () => import("./../../../src/pages/parcerias.js" /* webpackChunkName: "component---src-pages-parcerias-js" */),
  "component---src-pages-politica-de-envio-js": () => import("./../../../src/pages/politica-de-envio.js" /* webpackChunkName: "component---src-pages-politica-de-envio-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-templates-product-page-index-js": () => import("./../../../src/templates/ProductPage/index.js" /* webpackChunkName: "component---src-templates-product-page-index-js" */)
}

