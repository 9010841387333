import React from "react"

const defaultState = {
  showOverlay: "firstStart",
}

const NewsletterModalContext = React.createContext(defaultState)

class NewsletterModalProvider extends React.Component {
  state = {
    showOverlay: "firstStart",
  }

  closeOverlay = () => {
    this.setState({ showOverlay: false })
  }

  startOverlay = () => {
    this.setState({ showOverlay: true })
  }

  render() {
    const { children } = this.props
    const { showOverlay } = this.state
    return (
      <NewsletterModalContext.Provider
        value={{
          showOverlay,
          closeOverlay: this.closeOverlay,
          startOverlay: this.startOverlay,
        }}
      >
        {children}
      </NewsletterModalContext.Provider>
    )
  }
}

export default NewsletterModalContext

export { NewsletterModalProvider }
